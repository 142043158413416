var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frame"},[_c('div',{staticClass:"pt-4"}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"px-4 w-50"},[_c('form',{staticClass:"form d-flex",on:{"submit":function($event){$event.preventDefault();return _vm.search()}}},[_c('input',{staticClass:"form-control form-control-lg rounded-pill",attrs:{"type":"search","autofocus":"","placeholder":"검색어 입력"},on:{"input":function($event){_vm.q = $event.target.value},"keyup":function($event){return _vm.search()}}}),_c('button',{staticClass:"btn btn-default border shadow-sm rounded-pill ml-2",staticStyle:{"width":"100px"},attrs:{"type":"submit"}},[_vm._v("검색")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.q.length === 0),expression:"q.length === 0"}],staticClass:"p-4"},[_c('h5',{staticClass:"title pb-2 opacity-50"},[_vm._v("최근 업데이트된 내역")]),_vm._l((_vm.rows),function(row){return _c('div',{staticClass:"pb-1"},[_c('router-link',{attrs:{"to":{name: "document.view.record", params: {document_id: row.document_id, document_name: _vm.$options.filters.encodeReadableText(row.document_name), document_record_id: row.id }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"d-block px-2 py-1 w-100 updated",class:[isActive && 'router-link-active'],attrs:{"href":href},on:{"click":function($event){return navigate($event)}}},[_c('span',[_vm._v(_vm._s(row.preview_text || row.field_text || row.id))]),_c('small',{staticClass:"text-muted d-block"},[_vm._v(_vm._s(row.document_name))])])]}}],null,true)})],1)})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.q.length > 0),expression:"q.length > 0"}],staticClass:"p-4"},[_c('h5',{staticClass:"title pb-2"},[_vm._v("검색 결과")]),_vm._l((_vm.rows_search),function(row){return _c('div',{staticClass:"pb-1"},[_c('router-link',{attrs:{"to":{name: "document.view.record", params: {document_id: row.document_id, document_name: _vm.$options.filters.encodeReadableText(row.document_name), document_record_id: row.id }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"d-block px-2 py-1 w-100 updated",class:[isActive && 'router-link-active'],attrs:{"href":href},on:{"click":function($event){return navigate($event)}}},[_c('span',[_vm._v(_vm._s(row.preview_text || row.field_text || row.id))]),_c('small',{staticClass:"text-muted d-block"},[_vm._v(_vm._s(row.document_name))])])]}}],null,true)})],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }