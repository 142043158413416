<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.frame
  width: calc(100vw - 300px)
  height: 100vh !important
  overflow: scroll
.updated
  font-weight: 600
</style>
<template lang="pug">
div.frame
  //- .menu-bar.d-flex
    .p-2
      button.btn.btn-default(@click.stop='' type='button') 도움말
  .pt-4
  .d-flex
    .px-4.w-50
      form.form.d-flex(@submit.prevent='search()')
        input.form-control.form-control-lg.rounded-pill(type='search' autofocus placeholder='검색어 입력' @input='q = $event.target.value' @keyup='search()')
        button.btn.btn-default.border.shadow-sm.rounded-pill.ml-2(type='submit' style='width: 100px') 검색
  //- hr
  .p-4(v-show='q.length === 0')
    h5.title.pb-2.opacity-50 최근 업데이트된 내역
    //- pre {{rows}}
    div.pb-1(v-for='row in rows')
      //- a(href='#' @click.prevent='' @click.keydown.meta.prevent.stop='open_record_new(row)' @click.keydown.ctrl.prevent.stop='open_record_new(row)' @click.exact='open_record(row)' ) {{row.preview_text || rows.field_text}}
      router-link(
        :to='{name: "document.view.record", params: {document_id: row.document_id, document_name: $options.filters.encodeReadableText(row.document_name), document_record_id: row.id }}'
        v-slot='{href, navigate, isActive}'
        custom
      )
        a.d-block.px-2.py-1.w-100.updated(
          @click='navigate($event)' :href='href'
          :class="[isActive && 'router-link-active']"
        )
          span {{row.preview_text || row.field_text || row.id}}
          small.text-muted.d-block {{row.document_name}}

  .p-4(v-show='q.length > 0')
    h5.title.pb-2 검색 결과
    div.pb-1(v-for='row in rows_search')
      //- a(href='#' @click.prevent='' @click.keydown.meta.prevent.stop='open_record_new(row)' @click.keydown.ctrl.prevent.stop='open_record_new(row)' @click.exact='open_record(row)' ) {{row.preview_text || rows.field_text}}
      router-link(
        :to='{name: "document.view.record", params: {document_id: row.document_id, document_name: $options.filters.encodeReadableText(row.document_name), document_record_id: row.id }}'
        v-slot='{href, navigate, isActive}'
        custom
      )
        a.d-block.px-2.py-1.w-100.updated(
          @click='navigate($event)' :href='href'
          :class="[isActive && 'router-link-active']"
        )
          span {{row.preview_text || row.field_text || row.id}}
          small.text-muted.d-block {{row.document_name}}


</template>

<script>

import * as hangul from 'hangul-js'

export default {
  name: 'index',
  props: [],
  components: {

  },
  computed: {
    session() {
      return this.$store.state.session
    },
    property() {
      return this.$store.state.property
    },
  },
  watch: {
    'property'() {
      // this.load()
      // this.$store.dispatch('documents', this.property.id)
    },
    '$store.state.documents_by_id'() {
      this.load()
    },
  },
  mounted() {
    this.$store.dispatch('documents', this.property.id)
    this.load()
  },
  filters: {

  },
  data() {
    return {
      done: false,
      rows: [],
      rows_search: [],
      q: '',
    }
  },
  methods: {
    open_record_new(row) {
      const r = this.$router.resolve({
        name: 'document.view.record',
        params: {
          document_id: row.document_id,
          document_record_id: row.id,
        }
      })
      window.open(r.href, '_blank')
      return false
    },
    open_record(row) {
      this.$router.push({
        name: 'document.view.record',
        params: {
          document_name: 'sdfsd',
          document_id: row.document_id,
          document_record_id: row.id,
        }
      })
    },
    async load() {
      if (!this.$store.state.documents || this.$store.state.documents.length == 0) return
      if (!this.$store.state.documents_by_id) return
      if (!this.$store.state.property) return
      if (this.rows.length) return

      try {
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/records/last-updated`)
        if (r?.data?.message != 'ok') throw new Error('최근내역 가져오기 실패. ' + r.data.message)
        this.rows = r.data.rows.map(e => {
          // console.log('>>>>>>>', e.document_id)
          // console.log(this.$store.state.documents_by_id[e.document_id].name)
          e.document_name = this.$store.state.documents_by_id[e.document_id].name
          return e
        })
      } catch (error) {
        console.log(error)
      }
    },
    async search() {
      let q = String(this.q).trim()
      if (q.length === 0) {
        this.show_search = false
        return
      }
      if (q.length === 1) {
        if (!hangul.isComplete(q)) {
          this.show_search = false
          return false
        }
      }

      this.show_search = true

      try {
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/records/quick-find`, {
          params: {
            q,
          }
        })
        if (r?.data?.message != 'ok') throw new Error('검색 실패. ' + r.data.message)
        this.rows_search = r.data.rows.map(e => {
          e.document_name = this.$store.state.documents_by_id[e.document_id].name
          return e
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
